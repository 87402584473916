import { Controller } from "stimulus"
export default class extends Controller {
    static get targets() {
      return ["todoList", "todoCount", "totalCount", "todoButton"]
    }

    addConfigOnLiItems() {
      if (this.totalCountTarget.dataset.locked == "false") {
        var todoCount = 0
        const list = this.todoListTarget.children;
          Array.from(list).map(item => {
          item.dataset['action'] = this.initToggleCheck()
          if (item.classList.contains('checked')) {
            todoCount += 1
          }
        })
        this.todoCountTarget.textContent = todoCount
      }
    }

    initToggleCheck() {
      return "click->todo#toggleCheck"
    }

    update(set, evt) {
      let todoName = evt.target.id
      let formData = new FormData()
      const token = document.getElementsByName(
        'csrf-token'
        )[0].content

      formData.append(`book[${todoName}]`, set)
      fetch(this.data.get("update-url"), {
        body: formData,
        method: 'PUT',
        credentials: "include",
        dataType: "script",
        headers: {
          "X-CSRF-Token": token
        }
      })
    }


    toggleCheck(event) {
      var todoCount = parseInt(this.todoCountTarget.textContent)
      if (event.target.classList.contains('checked')) {
        todoCount -= 1
        this.update(false, event)
      } else {
        todoCount += 1
        this.update(true, event)
      }

      if (todoCount >= this.totalCountTarget.dataset.info) {
        this.todoButtonTarget.classList.remove("disabled")
        this.todoButtonTarget.disabled = false
      } else {
        this.todoButtonTarget.classList.add("disabled")
        this.todoButtonTarget.disabled = true
      }
      this.todoCountTarget.textContent = todoCount
      event.target.classList.toggle('checked')
    }

    connect() {
      this.addConfigOnLiItems()
    }
  }
