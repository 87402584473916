import React from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"

const grid = 2
const grey50 = "#fff"
const grey100 = "#E8E6E1"
const background = "#faf9f7"

const getItemStyle = (isDragging, draggableStyle) => ({
  padding: grid * 2,
  paddingLeft: "14px",
  width: "100%",

  background: isDragging ? grey50 : background,
  display: "inline-flex",
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? grey100 : background,
  padding: grid
})


export default function Pieces(props) {
  return (
    <Droppable droppableId={props.type} type={`droppablePiece`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {props.ordered_pieces.map((piece, index) => (
            <Draggable key={piece.id} draggableId={"a" + piece.id} index={index}>
              {(provided, snapshot) => (
                <div style={{ display: "flex" }}>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <span
                      {...provided.dragHandleProps}
                      style={{
                        display: "block",
                        margin: "0 10px",
                      }}
                    >
                      &#8942;&#8942;
                    </span>
                    <a className={props.piece_id == piece.id ? "current" : ""} href={`/pieces/${piece.id}/edit`}>
                      <span className="toc__name">{piece.title.length > 30 ? piece.title.substring(0,30) + "..." : piece.title}</span>
                      <span className="toc__dots"></span>
                      <span className="toc__content">{piece.metadata}</span>
                    </a>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
