import React, { useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Pieces from "./Pieces"
import Book from "./book"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const current_page = window.location.pathname

const grey50 = "#fff"
const grey100 = "#E8E6E1"
const background = "#faf9f7"

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? grey50 : background,
  marginLeft: "2px",
  marginRight: "2px",
  paddingBottom: "20px",
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? grey100 : background
})

const handleSave = (section_id, state) => Book.update(section_id, state)

export default function Nav(props) {
  const { piece_id, section_id, book_id, initial_data, book_title } = props
  const data = JSON.parse(initial_data)

  const [state, setState] = useState({ sections: data })

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "droppableSection") {
      const sections = reorder(state.sections, sourceIndex, destIndex)

      setState({ sections })
      handleSave(section_id, sections)

    } else if (result.type === "droppablePiece") {
      const pieces = state.sections.reduce((acc, section) => {
        acc[section.id] = section.ordered_pieces
        return acc
      }, {})

      const sourceSectionId = parseInt(result.source.droppableId)
      const destSectionId = parseInt(result.destination.droppableId)

      const sourcePieces = pieces[sourceSectionId]
      const destPieces = pieces[destSectionId]

      let newSections = [...state.sections]

      if (sourceSectionId === destSectionId) {
        const reorderedPieces = reorder(
          sourcePieces,
          sourceIndex,
          destIndex
          )
          newSections = newSections.map(section => {
            if (section.id == sourceSectionId) {
              section.ordered_pieces = reorderedPieces
            }
          return section
        })

        setState({
          sections: newSections
        })
        handleSave(section_id, newSections)

      } else {
        let newSourcePieces = [...sourcePieces]
        const [draggedPiece] = newSourcePieces.splice(sourceIndex, 1)

        let newDestPieces = [...destPieces]

        newDestPieces.splice(destIndex, 0, draggedPiece)

        newSections = newSections.map(section => {
          if (section.id === sourceSectionId) {
            section.ordered_pieces = newSourcePieces
          } else if (section.id === destSectionId) {
            section.ordered_pieces = newDestPieces
          }
          return section
        })

        setState({
          sections: newSections
        })
        handleSave(section_id, newSections)

      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="droppableSection">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <div className="cover-list">
              <a href={`/books/${book_id}/edit`} className={current_page == `/books/${book_id}/edit` ? "current" : ""}>Cover
                <span className="toc__content"> {book_title}... ID: {book_id}</span>
              </a>
            </div>
            {state.sections.map((section, index) => (
              <Draggable key={section.id} draggableId={section.id} index={index}>
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span
                        {...provided.dragHandleProps}
                        style={{
                          display: "inline-block",
                          margin: "0 10px 2px"
                        }}
                      >
                        &#8942;&#8942;
                      </span>

                      <span className="collapse-below">
                        <a className={section_id == section.id && current_page != `/books/${book_id}/edit` ? "current" : ""} href={`/sections/${section.id}/edit`}>{section.name}</a>
                        <span className="toc__content">{section.ordered_pieces.length} pieces</span>
                      </span>
                      {section_id != section.id &&
                        <>
                          <input type="checkbox" id={`expanded + ${section.id}`} className="collapse-below" name="toggle"/>
                          <label htmlFor={`expanded + ${section.id}`} title="Click to expand and collapse"></label>
                        </>
                      }
                      <span className={section_id != section.id ? "collapsible" : ""}>
                        <Pieces
                          ordered_pieces={section.ordered_pieces}
                          type={section.id}
                          piece_id={piece_id}
                        />
                        <a className="add-link" href={`/pieces/new?section_id=${section.id}`}><span className="add-icon">&#8853;</span> Add new piece</a>
                      </span>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <a className="add-link" href={`/sections/new?other_section_id=${state.sections[0].id}`}><span className="add-icon">&#8853;</span> Add new chapter</a>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
