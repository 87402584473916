import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "number_of_pages",
    "number_of_pages_output",
    "number_of_copies",
    "number_of_copies_output",
    "retail_price",
    "retail_price_output",
    "costs_output",
    "funds_output",
    "fundraising_output",
    "small_fundraising_output",
    "total_cost_output",
    "price_output",
    "copies_output",
    "show_button_output",
    "cost_per_book_output",
    "takings_output",
    "total_cost_gbp_output",
  ];

  sliders() {
    this.number_of_pages_outputTarget.textContent =
      this.number_of_pages +
      ` sides (approx ` +
      this.total_children +
      ` children with two sides each)`;
    this.number_of_copies_outputTarget.textContent =
      this.number_of_copies + ` copies`;
    this.retail_price_outputTarget.textContent =
      `£` + this.retail_price + ` per copy`;
  }

  calculate() {
    this.costs_text;
    this.funds_text;
    this.fundraising_text;
  }

  checkout() {
    this.total_cost_text;
    this.price_text;
    this.copies_text;
    this.show_button;
    this.cost_per_book;
    this.smaller_fundraising_text;
    this.takings_text;
    this.total_cost_with_gbp_text;
  }

  get total_cost_text() {
    this.total_cost_outputTarget.value = `${this.total_cost}`;
  }

  get total_cost_with_gbp_text() {
    this.total_cost_gbp_outputTarget.innerHTML = `£${this.total_cost}`;
  }

  get cost_per_book() {
    this.cost_per_book_outputTarget.innerHTML = `£${this.user_cost_per_unit}`;
  }

  get takings_text() {
    this.takings_outputTarget.innerHTML = `£${this.suggested_takings.toFixed(
      2
    )}`;
  }

  get price_text() {
    this.price_outputTarget.value = `${this.retail_price}`;
  }

  get copies_text() {
    this.copies_outputTarget.value = `${this.number_of_copies}`;
  }

  get costs_text() {
    this.costs_outputTarget.innerHTML = `The cost will be <b>£${this.total_cost}</b>.
    That's <b>£${this.user_cost_per_unit} per copy</b>.`;
  }

  get show_button() {
    this.show_button_outputTarget.disabled = false;
  }

  get funds_text() {
    if (this.retail_price == 0) {
      this.funds_outputTarget.textContent = `If you give away ${this.number_of_copies} copies,
      your cost is`;
    } else if (this.funds_raised < 1) {
      this.funds_outputTarget.textContent = `If you sell ${this.number_of_copies} copies for £${this.retail_price} each,
      you will take £${this.suggested_takings}. So after the cost of print, your costs are`;
    } else {
      this.funds_outputTarget.textContent = `If you sell ${this.number_of_copies} copies for £${this.retail_price} each,
      you will take £${this.suggested_takings}. So after paying for the printing, you will have raised`;
    }
  }

  get fundraising_text() {
    if (this.retail_price == 0 || this.funds_raised < 1) {
      this.fundraising_outputTarget.innerHTML = `<div style="color: IndianRed; font-size: 59px;">£${(
        this.funds_raised * -1
      ).toFixed(2)}</div>`;
    } else {
      this.fundraising_outputTarget.innerHTML = `<div style="color: mediumseagreen; font-size: 59px;">£${this.funds_raised}</div>`;
    }
  }

  get smaller_fundraising_text() {
    if (this.retail_price == 0 || this.funds_raised < 1) {
      this.small_fundraising_outputTarget.innerHTML = `<span style="color: IndianRed;">-£${(
        this.funds_raised * -1
      ).toFixed(2)}</span>`;
    } else {
      this.small_fundraising_outputTarget.innerHTML = `<span style="color: mediumseagreen;">£${this.funds_raised}</span>`;
    }
  }

  get number_of_pages() {
    return this.number_of_pagesTarget.value;
  }

  get number_of_copies() {
    return this.number_of_copiesTarget.value;
  }

  get retail_price() {
    return this.retail_priceTarget.value;
  }

  get printer_costs() {
    return {
      per_page: 0.023,
      per_cover: 1.35,
      shipping_percent: 6,
      handling_fee: 1.38,
    };
  }

  get our_costs() {
    return {
      markup: 0.36,
      handling_fee: 60,
    };
  }

  get total_children() {
    const prelims_pages = 6;
    const intro_pages_per_chapter = 6;
    const pages_per_child = 2;
    const pages_less_prelims = this.number_of_pages - prelims_pages;
    const number_of_chapters = Math.ceil(pages_less_prelims / 30);
    const chapter_intro_pages = number_of_chapters * intro_pages_per_chapter;
    const pages_of_contributions = pages_less_prelims - chapter_intro_pages;
    const children = pages_of_contributions / pages_per_child;
    return Math.round(children);
  }

  get printer_cost_per_unit() {
    const printer_cost_per_unit =
      this.number_of_pages * this.printer_costs["per_page"] +
      this.printer_costs["per_cover"];
    return printer_cost_per_unit;
  }

  get our_cost_per_unit() {
    const our_cost_per_unit =
      this.printer_cost_per_unit * this.our_costs["markup"];
    return our_cost_per_unit;
  }

  get cost_per_unit() {
    const cost_per_unit = this.printer_cost_per_unit + this.our_cost_per_unit;
    return cost_per_unit.toFixed(2);
  }

  get total_cost() {
    const cost_of_print = this.printer_cost_per_unit * this.number_of_copies;
    const shipping_cost =
      cost_of_print * (this.printer_costs["shipping_percent"] / 100);
    const printer_fee =
      shipping_cost + cost_of_print + this.printer_costs["handling_fee"];
    const our_unit_fee = this.our_cost_per_unit * this.number_of_copies;
    const our_fee = this.our_costs["handling_fee"] + our_unit_fee;
    const total_cost = printer_fee + our_fee;

    return total_cost.toFixed(2);
  }

  get user_cost_per_unit() {
    return (this.total_cost / this.number_of_copies).toFixed(2);
  }

  get suggested_takings() {
    return this.retail_price * this.number_of_copies;
  }

  get funds_raised() {
    return (this.suggested_takings - this.total_cost).toFixed(2);
  }
}
