import React from "react"
import ReactDOM from "react-dom"
import { Controller } from "stimulus"
import Nav from "../nav.js"

export default class extends Controller {

  connect() {
    const piece_id = this.data.get("piece-id")
    const section_id = this.data.get("section-id")
    const book_id = this.data.get("book-id")
    const initial_data = this.data.get("initial-data")
    const book_title = this.data.get("book-title")

    ReactDOM.render(<Nav book_id={book_id} piece_id={piece_id} section_id={section_id} initial_data={initial_data} book_title={book_title}/>, this.element)
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }

}
