import { Controller } from "stimulus"
import { rails_ujs } from "packs/application.js"

export default class extends Controller {
  static targets = [ "form", "status" ]

  connect() {
    this.timeout  = null
    this.duration = 1000
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "Saving..."
      rails_ujs.fire(this.formTarget, 'submit')
    }, this.duration)
  }

  success() {
    this.setStatus("Saved!")
  }

  error() {
    this.setStatus("Unable to save!")
  }

  setStatus(message) {
    this.statusTarget.textContent = message

    this.timeout = setTimeout(() => {
      const autosave = document.getElementById("autosave")

      if ( autosave != null ) {
        this.statusTarget.textContent = "Autosave on"
      } else {
        this.statusTarget.textContent = "Autosave off"
      }
    }, 2000)
  }
}
